import { StaticImageData } from 'next/legacy/image'
import { CommitHash } from '../globals/constants'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function importAll(r: __WebpackModuleApi.RequireContext) {
    return r.keys().map((element: any) => r(element))
}
export const backgroundImages = importAll(
    // eslint-disable-next-line unicorn/prefer-module
    require.context('../assets/images/landing/backgrounds/', false)
)
// basic hash function
// NOTE: don't change this hash function since it's used for identicons based on user id
export const hashCode = (s: string): number =>
    [...s].reduce((a, b) => {
        a = (a << 5) - a + (b.codePointAt(0) ?? 1)
        return a & a
    }, 0)
// cyrb53a (c) 2023 bryc (github.com/bryc)
export const cyrb53a = function (s: string, seed = 0): number {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed
    for (let i = 0, ch; i < s.length; i++) {
        // eslint-disable-next-line unicorn/prefer-code-point
        ch = s.charCodeAt(i)
        h1 = Math.imul(h1 ^ ch, 0x85ebca77)
        h2 = Math.imul(h2 ^ ch, 0xc2b2ae3d)
    }
    h1 ^= Math.imul(h1 ^ (h2 >>> 15), 0x735a2d97)
    h2 ^= Math.imul(h2 ^ (h1 >>> 15), 0xcaf649a9)
    h1 ^= h2 >>> 16
    h2 ^= h1 >>> 16
    return 2097152 * (h2 >>> 0) + (h1 >>> 11)
}

let index = Math.abs(cyrb53a(CommitHash) % backgroundImages.length)
if (index === 30) index++ // egg
export const backgroundIndex = index

export const defaultBackground = backgroundImages[backgroundIndex].default

export const getBackground = (index: number): StaticImageData =>
    backgroundImages[index % backgroundImages.length].default
